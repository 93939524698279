declare const process: {
  env: {
    REACT_APP_API_URL: string;
    REACT_APP_APPLE_STORE_URL: string;
    REACT_APP_BETA_ACCESS_KEY: string;
    REACT_APP_BUILD_VERSION: string;
    REACT_APP_CHAT_URL: string;
    REACT_APP_CODE_PUSH_DEPLOYMENT_KEY_ANDROID: string;
    REACT_APP_CODE_PUSH_DEPLOYMENT_KEY_IOS: string;
    REACT_APP_CUSTOMER_CONTACT_EMAIL: string;
    REACT_APP_CUSTOMER_CONTACT_PHONE: string;
    REACT_APP_CUSTOMER_NAME: string;
    REACT_APP_DISABLE_WALLET_CARD_HOLDER_REGISTRY: string;
    REACT_APP_ENABLE_ANALYTICS: string;
    REACT_APP_ENABLE_BACKEND_VERSION: string;
    REACT_APP_ENABLE_BLOCK_OLD_VERSIONS: string;
    REACT_APP_ENABLE_CRASHLYTICS: string;
    REACT_APP_ENABLE_DEVICE_KEY: string;
    REACT_APP_ENABLE_FEATURE_TOGGLE: string;
    REACT_APP_ENABLE_HIDE_EXEMPTION: string;
    REACT_APP_ENABLE_INCOME_REPORT: string;
    REACT_APP_ENABLE_P2P_WITH_DOCUMENT: string;
    REACT_APP_ENABLE_PHYSICAL_CARD: string;
    REACT_APP_ENABLE_PIX: string;
    REACT_APP_ENABLE_PIX_LIMITS: string;
    REACT_APP_ENABLE_REWARDS: string;
    REACT_APP_ENABLE_SENSOR_LOGIN: string;
    REACT_APP_ENABLE_SELFIE_MASK: string;
    REACT_APP_ENABLE_SPD_RESTRICTION: string;
    REACT_APP_ENABLE_TRANSPORT_CARD_RECHARGE: string;
    REACT_APP_ENABLE_MOBILE_RECHARGE: string;
    REACT_APP_ENABLE_VIRTUAL_CARD: string;
    REACT_APP_ENABLE_WALLET: string;
    REACT_APP_ENABLE_WITHDRAW: string;
    REACT_APP_ENABLE_NOTIFICATION_HISTORY: string;
    REACT_APP_ENABLE_UNBLOCK_INCORRECT_CARD_PASSWORD: string;
    REACT_APP_FAQ_URL: string;
    REACT_APP_FEATURE_TOGGLE_PREFIX: string;
    REACT_APP_FIREBASE_ENV: string;
    REACT_APP_LUCREE_ENABLE_CREDIT: string;
    REACT_APP_LUCREE_ENABLE_INSURANCE: string;
    REACT_APP_LUCREE_CREDIT_FAQ_URL: string;
    REACT_APP_LUCREE_SCR_MESSAGE: string;
    REACT_APP_NEW_LOGIN_FLOW: string;
    REACT_APP_NOTIFICATION_ADAPTER: string;
    REACT_APP_NOTIFICATION_APP_ID: string;
    REACT_APP_NUMERIC_APP_PASSWORD: string;
    REACT_APP_ONBOARDING_ALLOW_COMPANY: string;
    REACT_APP_ONBOARDING_CARD_NO_NAME: string;
    REACT_APP_ONBOARDING_ONLY: string;
    REACT_APP_ONBOARDING_REQUIRE_PHOTO_RESIDENTIAL: string;
    REACT_APP_PLAY_STORE_URL: string;
    REACT_APP_SAFEGUARD_API_KEY: string;
    REACT_APP_SAFEGUARD_API_URL: string;
    REACT_APP_SKIP_TRANSACTION_PHONE_VERIFICATION: string;
    REACT_APP_TENANT_DOCUMENT_NUMBER: string;
    REACT_APP_WHATSAPP_NUMBER: string;
    REACT_APP_ENABLE_NO_REPEATED_OR_SEQUENTIAL_PASSWORD: string;
  };
};

export const REACT_APP_API_URL: string = process.env.REACT_APP_API_URL;

export const REACT_APP_APPLE_STORE_URL: string =
  process.env.REACT_APP_APPLE_STORE_URL;

export const REACT_APP_BETA_ACCESS_KEY: string =
  process.env.REACT_APP_BETA_ACCESS_KEY;

export const REACT_APP_BUILD_VERSION: string =
  process.env.REACT_APP_BUILD_VERSION;

export const REACT_APP_CHAT_URL: string = process.env.REACT_APP_CHAT_URL;

export const REACT_APP_CODE_PUSH_DEPLOYMENT_KEY_ANDROID =
  process.env.REACT_APP_CODE_PUSH_DEPLOYMENT_KEY_ANDROID;

export const REACT_APP_CODE_PUSH_DEPLOYMENT_KEY_IOS =
  process.env.REACT_APP_CODE_PUSH_DEPLOYMENT_KEY_IOS;

export const REACT_APP_CUSTOMER_CONTACT_EMAIL: string =
  process.env.REACT_APP_CUSTOMER_CONTACT_EMAIL;

export const REACT_APP_CUSTOMER_CONTACT_PHONE: string =
  process.env.REACT_APP_CUSTOMER_CONTACT_PHONE || '0800 941 7590';

export const REACT_APP_CUSTOMER_NAME: string =
  process.env.REACT_APP_CUSTOMER_NAME || 'Vizir Bank';

export const REACT_APP_DISABLE_WALLET_CARD_HOLDER_REGISTRY: boolean =
  process.env.REACT_APP_DISABLE_WALLET_CARD_HOLDER_REGISTRY === 'true';

export const REACT_APP_ENABLE_ANALYTICS: boolean =
  process.env.REACT_APP_ENABLE_ANALYTICS === 'true';

export const REACT_APP_ENABLE_BACKEND_VERSION: boolean =
  process.env.REACT_APP_ENABLE_BACKEND_VERSION === 'true';

export const REACT_APP_ENABLE_BLOCK_OLD_VERSIONS: boolean =
  process.env.REACT_APP_ENABLE_BLOCK_OLD_VERSIONS === 'true';

export const REACT_APP_ENABLE_CRASHLYTICS: boolean =
  process.env.REACT_APP_ENABLE_CRASHLYTICS === 'true';

export const REACT_APP_ENABLE_DEVICE_KEY: boolean =
  process.env.REACT_APP_ENABLE_DEVICE_KEY === 'true';

export const REACT_APP_ENABLE_FEATURE_TOGGLE: boolean =
  process.env.REACT_APP_ENABLE_FEATURE_TOGGLE === 'true';

export const REACT_APP_ENABLE_HIDE_EXEMPTION: boolean =
  process.env.REACT_APP_ENABLE_HIDE_EXEMPTION === 'true';

export const REACT_APP_ENABLE_INCOME_REPORT: boolean =
  process.env.REACT_APP_ENABLE_INCOME_REPORT === 'true';

export const REACT_APP_ENABLE_P2P_WITH_DOCUMENT: boolean =
  process.env.REACT_APP_ENABLE_P2P_WITH_DOCUMENT === 'true';

export const REACT_APP_ENABLE_PHYSICAL_CARD: boolean =
  process.env.REACT_APP_ENABLE_PHYSICAL_CARD === 'true';

export const REACT_APP_ENABLE_PIX: boolean =
  process.env.REACT_APP_ENABLE_PIX === 'true';

export const REACT_APP_ENABLE_PIX_LIMITS: boolean =
  process.env.REACT_APP_ENABLE_PIX_LIMITS === 'true';

export const REACT_APP_ENABLE_REWARDS: boolean =
  process.env.REACT_APP_ENABLE_REWARDS === 'true';

export const REACT_APP_ENABLE_SENSOR_LOGIN: boolean =
  process.env.REACT_APP_ENABLE_SENSOR_LOGIN === 'true';

export const REACT_APP_ENABLE_SPD_RESTRICTION: boolean =
  process.env.REACT_APP_ENABLE_SPD_RESTRICTION === 'true';

export const REACT_APP_ENABLE_TRANSPORT_CARD_RECHARGE: boolean =
  process.env.REACT_APP_ENABLE_TRANSPORT_CARD_RECHARGE === 'true';

export const REACT_APP_ENABLE_MOBILE_RECHARGE: boolean =
  process.env.REACT_APP_ENABLE_MOBILE_RECHARGE === 'true';

export const REACT_APP_ENABLE_VIRTUAL_CARD: boolean =
  process.env.REACT_APP_ENABLE_VIRTUAL_CARD === 'true';

export const REACT_APP_ENABLE_WALLET: boolean =
  process.env.REACT_APP_ENABLE_WALLET === 'true';

export const REACT_APP_ENABLE_WITHDRAW: boolean =
  process.env.REACT_APP_ENABLE_WITHDRAW === 'true';

export const REACT_APP_ENABLE_NOTIFICATION_HISTORY: boolean =
  process.env.REACT_APP_ENABLE_NOTIFICATION_HISTORY === 'true';

export const REACT_APP_ENABLE_NO_REPEATED_OR_SEQUENTIAL_PASSWORD: boolean =
  process.env.REACT_APP_ENABLE_NO_REPEATED_OR_SEQUENTIAL_PASSWORD === 'true';

export const REACT_APP_ENABLE_UNBLOCK_INCORRECT_CARD_PASSWORD: boolean =
  process.env.REACT_APP_ENABLE_UNBLOCK_INCORRECT_CARD_PASSWORD === 'true';

export const REACT_APP_FAQ_URL: string = process.env.REACT_APP_FAQ_URL;

export const REACT_APP_FEATURE_TOGGLE_PREFIX: string =
  process.env.REACT_APP_FEATURE_TOGGLE_PREFIX || '';

export const REACT_APP_FIREBASE_ENV: string =
  process.env.REACT_APP_FIREBASE_ENV || 'DEV';

export const REACT_APP_LUCREE_ENABLE_CREDIT: boolean =
  process.env.REACT_APP_LUCREE_ENABLE_CREDIT === 'true' || false;

export const REACT_APP_LUCREE_ENABLE_INSURANCE: boolean =
  process.env.REACT_APP_LUCREE_ENABLE_INSURANCE === 'true' || false;

export const REACT_APP_LUCREE_CREDIT_FAQ_URL: string =
  process.env.REACT_APP_LUCREE_CREDIT_FAQ_URL || '';

export const REACT_APP_LUCREE_SCR_MESSAGE: string =
  process.env.REACT_APP_LUCREE_SCR_MESSAGE || '';

export const REACT_APP_NEW_LOGIN_FLOW: boolean =
  process.env.REACT_APP_NEW_LOGIN_FLOW === 'true';

export const REACT_APP_NOTIFICATION_ADAPTER: string =
  process.env.REACT_APP_NOTIFICATION_ADAPTER;

export const REACT_APP_NOTIFICATION_APP_ID: string =
  process.env.REACT_APP_NOTIFICATION_APP_ID;

export const REACT_APP_NUMERIC_APP_PASSWORD: boolean =
  process.env.REACT_APP_NUMERIC_APP_PASSWORD === 'true';

export const REACT_APP_ONBOARDING_ALLOW_COMPANY: boolean =
  process.env.REACT_APP_ONBOARDING_ALLOW_COMPANY === 'true';

export const REACT_APP_ONBOARDING_CARD_NO_NAME: boolean =
  process.env.REACT_APP_ONBOARDING_CARD_NO_NAME === 'true';

export const REACT_APP_ONBOARDING_ONLY: boolean =
  process.env.REACT_APP_ONBOARDING_ONLY === 'true';

export const REACT_APP_ONBOARDING_REQUIRE_PHOTO_RESIDENTIAL: boolean =
  process.env.REACT_APP_ONBOARDING_REQUIRE_PHOTO_RESIDENTIAL === 'true';

export const REACT_APP_PLAY_STORE_URL: string =
  process.env.REACT_APP_PLAY_STORE_URL;

export const REACT_APP_SAFEGUARD_API_KEY =
  process.env.REACT_APP_SAFEGUARD_API_KEY || '';

export const REACT_APP_SAFEGUARD_API_URL =
  process.env.REACT_APP_SAFEGUARD_API_URL || '';

export const REACT_APP_SKIP_TRANSACTION_PHONE_VERIFICATION: boolean =
  process.env.REACT_APP_SKIP_TRANSACTION_PHONE_VERIFICATION === 'true';

export const REACT_APP_TENANT_DOCUMENT_NUMBER: string =
  process.env.REACT_APP_TENANT_DOCUMENT_NUMBER || '';

export const REACT_APP_WHATSAPP_NUMBER: string =
  process.env.REACT_APP_WHATSAPP_NUMBER || '';
