import {ImageManager} from '@vizir-banking/banking-app-core/dist/hooks/image-manager';

import cardBackImage from '~/assets/images/card_back.png';
import cardImage from '~/assets/images/card.png';
import emptyCardImage from '~/assets/images/empty_card.png';
import cardPlaceholderImage from '~/assets/images/card_placeholder.png';
import sadFaceImage from '~/assets/images/sad_face.png';
import modalCheckImage from '~/assets/images/modal_check.png';
import loggedBackgroundImage from '~/assets/images/logged_background.png';
import checkSuccessImage from '~/assets/images/check_success.png';
import logo from '~/assets/images/logo.png';
import splash from '~/assets/images/img-splash.png';
import corner from '~/assets/images/corner.png';
import photoSelfie from '~/assets/images/photo_selfie.png';
import tutorialNext from '~/assets/images/tutorial_next.png';
import tutorialConfirm from '~/assets/images/tutorial_confirm.png';
import successImage from '~/assets/images/img_success.png';
import residential from '~/assets/images/residential.png';
import documentBack from '~/assets/images/document_back.png';
import rgBack from '~/assets/images/rg-back.jpg';
import rgFront from '~/assets/images/rg-front.jpg';
import cnhBack from '~/assets/images/cnh-back.jpg';
import cnhFront from '~/assets/images/cnh-front.jpg';
import crnmBack from '~/assets/images/crnm-back.jpg';
import crnmFront from '~/assets/images/crnm-front.jpg';
import companyOpen from '~/assets/images/company_open.png';
import contractChange from '~/assets/images/contract_change.png';
import backgroundImage from '~/assets/images/national_register_pj.png';
import appleImage from '~/assets/images/apple.png';
import googleImage from '~/assets/images/google.png';
import womanNotebook from '~/assets/images/woman-notebook.png';

export const registerImages = (imageManager: typeof ImageManager): void => {
  imageManager.add('card_back.png', cardBackImage);
  imageManager.add('empty_card.png', emptyCardImage);
  imageManager.add('card.png', cardImage);
  imageManager.add('card_placeholder.png', cardPlaceholderImage);
  imageManager.add('sad_face.png', sadFaceImage);
  imageManager.add('modal_check.png', modalCheckImage);
  imageManager.add('logged_background.png', loggedBackgroundImage);
  imageManager.add('check_success.png', checkSuccessImage);
  imageManager.add('logo.png', logo);
  imageManager.add('img-splash.png', splash);
  imageManager.add('corner.png', corner);
  imageManager.add('photo_selfie.png', photoSelfie);
  imageManager.add('tutorial_next.png', tutorialNext);
  imageManager.add('tutorial_confirm.png', tutorialConfirm);
  imageManager.add('img_success.png', successImage);
  imageManager.add('residential.png', residential);
  imageManager.add('document_back.png', documentBack);
  imageManager.add('rg-back.png', rgBack);
  imageManager.add('rg-front.png', rgFront);
  imageManager.add('cnh-back.png', cnhBack);
  imageManager.add('cnh-front.png', cnhFront);
  imageManager.add('crnm-back.png', crnmBack);
  imageManager.add('crnm-front.png', crnmFront);
  imageManager.add('company_open.png', companyOpen);
  imageManager.add('contract-change.png', contractChange);
  imageManager.add('national_register_pj.png', backgroundImage);
  imageManager.add('apple.png', appleImage);
  imageManager.add('google.png', googleImage);
  imageManager.add('woman-notebook.png', womanNotebook);
};
